import paths from "~/constants/paths";

export default defineNuxtRouteMiddleware((to) => {
  const { $features } = useNuxtApp();

  if (!$features?.home && to.path === paths.home) {
    return navigateTo(paths.products.index);
  }

  if (!$features?.bundles && to.path === paths.bundles) {
    return navigateTo(paths.home);
  }
});
