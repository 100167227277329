export const useApi = async <T>(
  url: string,
  params: any = {},
  baseUrl: string | null = null,
): Promise<{ res: T | undefined }> => {
  const { $baseUrl } = useNuxtApp();
  const runTimeConfig = useRuntimeConfig();
  const localeCookie = useCookie("locale-cookie");

  // Validate and set global headers
  const globalHeaders: Record<string, string> = {
    "X-User-Agent":
      runTimeConfig.public.env.VERSION == "PREVIEW" ? "Web/Preview" : "",
    "Accept-Language": localeCookie.value ?? "en",
  };

  // Conditionally add Authorization header if provided
  if (params.authToken) {
    globalHeaders["Authorization"] = `Bearer ${params.authToken}`;
  }

  // Merge global headers with additional headers from params
  const headers: Record<string, string> = {
    ...globalHeaders,
    ...(params.headers || {}),
  };

  // Remove any invalid characters from headers
  for (const key in headers) {
    if (headers[key] && typeof headers[key] === "string") {
      headers[key] = headers[key].replace(/[^\x20-\x7E]/g, "");
    }
  }

  const opts = {
    ...params,
    headers,
  };

  const res = await $fetch<T>(`${baseUrl ?? $baseUrl}${url}`, opts);

  return { res: res as T | undefined };
};
