import * as Sentry from "@sentry/nuxt";
import tenantConfig from "~/tenant-config.json";

export default defineNuxtPlugin(() => {
  const runtimeConfigs = useRuntimeConfig();
  const baseUrl = useCookie("baseUrl");
  const tenantPath =
    runtimeConfigs.public.env.VERSION == "PREVIEW"
      ? baseUrl.value ?? ""
      : tenantConfig.baseUrl;
  const SENTRY_DSN = runtimeConfigs.public.SENTRY_VUE_DSN;
  const ENV = runtimeConfigs.public.ENVIRONMENT;

  const router = useRouter();

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    trackComponents: true,
    sendDefaultPii: true,

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.browserApiErrorsIntegration({
        setTimeout: true,
        setInterval: true,
        requestAnimationFrame: true,
        XMLHttpRequest: true,
        eventTarget: true,
      }),
    ],

    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.01,
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        );

        event.extra = { originalException: hint.originalException?.data };
      }
      return event;
    },
  });

  Sentry.setTag("tenant", tenantPath);
});
