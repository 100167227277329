import { default as _404gLkzNPdHucMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/404.vue?macro=true";
import { default as accountj67uwJTvJgMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/account.vue?macro=true";
import { default as bundleswu3UeEZZKyMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue?macro=true";
import { default as cartZ52mKWnT4OMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/cart.vue?macro=true";
import { default as indexc57PL3wBoqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue?macro=true";
import { default as contactUsvA3rY0c5suMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue?macro=true";
import { default as _91id_93cNsoiHsbpqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue?macro=true";
import { default as faqsf1VX31ouVMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/faq.vue?macro=true";
import { default as indexY9Jn2MBYlRMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/index.vue?macro=true";
import { default as loginBdm4q57I8UMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/login.vue?macro=true";
import { default as order_45placedoG2q02WJFNMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue?macro=true";
import { default as contentpLiGnl4G3VMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failed882IBCKblCMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionVo9bCRwYg6Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue?macro=true";
import { default as _91id_93cXNeSq41H8Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue?macro=true";
import { default as indexcLhTb11Z4hMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue?macro=true";
import { default as registerma6WvvbFY5Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/register.vue?macro=true";
import { default as resetZjwCewCHReMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/reset.vue?macro=true";
import { default as staticlWrdbQlwIgMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/static.vue?macro=true";
import { default as tenantLogin8uiwOQQUbgMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/tenantLogin.vue?macro=true";
import { default as verifyOmWjXVY23VMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/verify.vue?macro=true";
import { default as wishlistKpl0Nx3aTqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/404.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: accountj67uwJTvJgMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/account.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/cart.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexc57PL3wBoqMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue")
  },
  {
    name: "contactUs",
    path: "/contactUs",
    meta: contactUsvA3rY0c5suMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue")
  },
  {
    name: "custom-id",
    path: "/custom/:id()",
    meta: _91id_93cNsoiHsbpqMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginBdm4q57I8UMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/login.vue")
  },
  {
    name: "order-placed",
    path: "/order-placed",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue")
  },
  {
    name: "pages-key-content",
    path: "/pages/:key()/content",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue")
  },
  {
    name: "payment-failed",
    path: "/payment-failed",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue")
  },
  {
    name: "processing-transaction",
    path: "/processing-transaction",
    meta: processing_45transactionVo9bCRwYg6Meta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_93cXNeSq41H8Meta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexcLhTb11Z4hMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerma6WvvbFY5Meta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/register.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/reset.vue")
  },
  {
    name: "static",
    path: "/static",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/static.vue")
  },
  {
    name: "tenantLogin",
    path: "/tenantLogin",
    meta: tenantLogin8uiwOQQUbgMeta || {},
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/tenantLogin.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/verify.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue")
  }
]