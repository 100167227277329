<template>
  <svg viewBox="0 0 317 244" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_740_196)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M152.51 41.0309C176.578 40.5067 200.902 24.808 222.889 34.0887C245.244 43.5251 254.22 68.3562 265.713 88.8138C277.903 110.512 297.785 132.796 291.773 156.704C285.751 180.657 255.895 190.324 236.157 206.359C219.601 219.81 206.682 239.455 185.238 243.257C164.235 246.981 145.68 231.56 125.122 226.046C104.304 220.463 81.4435 222.285 63.6409 210.643C43.0654 197.189 20.6157 179.597 18.0759 156.047C15.5369 132.505 39.4091 114.832 51.0492 93.8893C61.9048 74.3585 63.7582 47.7507 84.1834 37.1098C104.587 26.4803 129.227 41.538 152.51 41.0309Z"
        fill="#F7F7F7"
      />
      <path
        d="M93.8026 93.8654C93.8006 98.3233 92.0294 102.598 88.8779 105.751C85.7265 108.904 81.4525 110.677 76.9947 110.681H14.1878C10.9904 110.681 7.90579 109.499 5.52703 107.363C3.14827 105.226 1.64329 102.286 1.30147 99.1068C0.959652 95.9277 1.80512 92.7345 3.6753 90.1412C5.54549 87.5478 8.30838 85.7373 11.4327 85.0578C11.6827 81.2748 13.3604 77.7279 16.1265 75.1351C18.8926 72.5422 22.5404 71.097 26.3318 71.0919C27.867 71.092 29.3925 71.3357 30.8514 71.814C33.0647 67.533 36.5359 64.0326 40.7982 61.7836C45.0604 59.5346 49.9092 58.6449 54.6923 59.2341C59.4754 59.8233 63.9633 61.8633 67.5523 65.0795C71.1412 68.2958 73.6591 72.534 74.7673 77.2241C77.1406 76.9034 79.555 77.0942 81.8485 77.7837C84.1419 78.4732 86.2614 79.6454 88.0644 81.2217C89.8674 82.7979 91.3123 84.7417 92.302 86.9225C93.2917 89.1033 93.8034 91.4706 93.8026 93.8654Z"
        fill="#EEEEEE"
      />
      <path
        d="M203.016 192.605L190.529 174.042C200.595 165.098 206.939 152.703 208.306 139.307C209.673 125.912 205.964 112.49 197.912 101.698C189.86 90.9051 178.051 83.5268 164.821 81.0216C151.591 78.5164 137.902 81.0669 126.462 88.1687C115.021 95.2704 106.662 106.406 103.037 119.375C99.4124 132.343 100.786 146.199 106.885 158.204C112.985 170.209 123.367 179.488 135.979 184.206C148.59 188.924 162.513 188.738 174.995 183.685L187.167 201.7C187.764 202.741 188.56 203.653 189.51 204.386C190.46 205.119 191.545 205.658 192.703 205.972C193.861 206.285 195.07 206.368 196.26 206.214C197.45 206.061 198.598 205.674 199.638 205.077C200.679 204.48 201.592 203.684 202.325 202.734C203.058 201.784 203.596 200.699 203.91 199.541C204.224 198.383 204.306 197.174 204.153 195.984C203.999 194.794 203.613 193.646 203.016 192.605Z"
        fill="#D2D2D2"
      />
      <path
        d="M154.93 172.091C176.105 172.091 193.271 154.925 193.271 133.749C193.271 112.574 176.105 95.4081 154.93 95.4081C133.754 95.4081 116.588 112.574 116.588 133.749C116.588 154.925 133.754 172.091 154.93 172.091Z"
        fill="#F7F7F7"
      />
      <path
        d="M194.098 156.288C192.82 158.999 191.253 161.565 189.425 163.941C187.534 166.303 185.412 168.47 183.09 170.41C180.72 172.318 178.17 173.992 175.477 175.409C170.028 178.206 164.046 179.81 157.928 180.113C156.403 180.127 154.881 180.183 153.362 180.091C151.845 180.006 150.333 179.837 148.835 179.586C142.865 178.627 137.193 176.319 132.251 172.837C137.572 175.322 143.27 176.899 149.111 177.506C154.865 178.068 160.673 177.592 166.258 176.101C169.036 175.345 171.745 174.351 174.353 173.133C176.953 171.872 179.449 170.407 181.818 168.752C184.168 167.034 186.381 165.136 188.438 163.076C190.48 160.948 192.371 158.679 194.098 156.288Z"
        fill="url(#paint0_linear_740_196)"
      />
      <path
        d="M110.639 142.717C108.531 134.956 108.699 126.752 111.125 119.084C112.326 115.221 114.053 111.542 116.258 108.151C117.363 106.454 118.584 104.836 119.913 103.309C121.231 101.772 122.651 100.324 124.162 98.9755C125.685 97.6397 127.29 96.4001 128.967 95.264C129.827 94.73 130.657 94.1459 131.547 93.6625L132.866 92.911L134.223 92.2307C135.122 91.7657 136.069 91.4004 136.991 90.9889C137.941 90.642 138.87 90.2416 139.84 89.9522C141.754 89.3123 143.713 88.8121 145.7 88.4558C149.659 87.7566 153.696 87.5997 157.698 87.9894C161.658 88.3932 165.539 89.3684 169.219 90.8845C165.426 89.9121 161.533 89.3828 157.618 89.307C153.752 89.249 149.893 89.6531 146.123 90.5107C142.386 91.3908 138.774 92.7322 135.369 94.5039C133.67 95.3833 132.026 96.3628 130.443 97.4371C128.873 98.5276 127.371 99.7126 125.945 100.986C120.175 106.081 115.762 112.531 113.104 119.755C110.442 127.098 109.597 134.976 110.639 142.717Z"
        fill="url(#paint1_linear_740_196)"
      />
      <path
        d="M81.3598 173.409V188.978C81.3598 202.499 82.1793 203.524 96.7246 204.957V210.694H48.7861V204.957C64.151 203.524 65.1753 202.294 65.1753 188.773V173.409H11.0911V167.263C30.5533 139.605 51.6544 109.695 72.1409 81.2188H81.3598V163.574H101.437V173.409L81.3598 173.409ZM65.1753 102.73C52.2691 121.577 37.9282 142.473 23.7928 163.574H65.1753V102.73Z"
        fill="#D2D2D2"
      />
      <path
        d="M283.765 173.409V188.978C283.765 202.499 284.584 203.524 299.13 204.957V210.694H251.191V204.957C266.556 203.524 267.58 202.294 267.58 188.773V173.409H213.496V167.263C232.958 139.605 254.059 109.695 274.546 81.2188H283.765V163.574H303.842V173.409L283.765 173.409ZM267.58 102.73C254.674 121.577 240.333 142.473 226.198 163.574H267.58V102.73Z"
        fill="#D2D2D2"
      />
      <path
        d="M316.034 46.5049C316.031 52.3766 313.698 58.0071 309.547 62.1599C305.396 66.3127 299.767 68.6482 293.895 68.6534H211.169C206.957 68.6538 202.894 67.0972 199.761 64.2831C196.628 61.469 194.646 57.5959 194.195 53.4086C193.745 49.2213 194.859 45.0154 197.322 41.5995C199.785 38.1836 203.425 35.7989 207.54 34.904C207.869 29.9211 210.079 25.2493 213.722 21.8341C217.366 18.419 222.17 16.5154 227.164 16.5087C229.186 16.5088 231.196 16.8299 233.117 17.4598C236.032 11.8211 240.605 7.21055 246.219 4.24825C251.833 1.28594 258.219 0.114033 264.519 0.890158C270.819 1.66628 276.731 4.35321 281.458 8.5895C286.185 12.8258 289.502 18.4082 290.961 24.5858C294.087 24.1633 297.267 24.4147 300.288 25.3228C303.309 26.231 306.101 27.775 308.475 29.8511C310.85 31.9273 312.753 34.4876 314.057 37.36C315.361 40.2324 316.035 43.3505 316.034 46.5049Z"
        fill="#EEEEEE"
      />
      <path
        d="M315.917 219.687C315.915 224.145 314.144 228.42 310.993 231.572C307.841 234.725 303.567 236.498 299.109 236.502H236.302C233.105 236.503 230.021 235.321 227.642 233.184C225.263 231.048 223.758 228.107 223.416 224.928C223.074 221.749 223.92 218.556 225.79 215.963C227.66 213.369 230.423 211.559 233.547 210.879C233.797 207.096 235.475 203.549 238.241 200.957C241.007 198.364 244.655 196.919 248.446 196.913C249.982 196.914 251.507 197.157 252.966 197.636C255.179 193.355 258.651 189.854 262.913 187.605C267.175 185.356 272.024 184.466 276.807 185.056C281.59 185.645 286.078 187.685 289.667 190.901C293.256 194.117 295.774 198.356 296.882 203.046C299.255 202.725 301.67 202.916 303.963 203.605C306.257 204.295 308.376 205.467 310.179 207.043C311.982 208.619 313.427 210.563 314.417 212.744C315.406 214.925 315.918 217.292 315.917 219.687Z"
        fill="#EEEEEE"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_740_196"
        x1="202.342"
        y1="152.687"
        x2="136.431"
        y2="174.657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E5E5E5" />
        <stop offset="1" stop-color="#E5E5E5" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_740_196"
        x1="111.781"
        y1="137.147"
        x2="142.325"
        y2="87.312"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E5E5E5" />
        <stop offset="1" stop-color="#E5E5E5" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_740_196">
        <rect
          width="315.5"
          height="243.831"
          fill="white"
          transform="translate(0.75)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
