export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();
  const tenantAuthCookie = useCookie("tenantAuth");

  // Preview Global Gaurd
  if (
    config.public.env.VERSION === "PREVIEW" &&
    to.path !== "/tenantLogin" &&
    !tenantAuthCookie.value
  ) {
    return navigateTo("/tenantLogin");
  }

  if (
    config.public.env.VERSION === "PREVIEW" &&
    to.path === "/tenantLogin" &&
    tenantAuthCookie.value
  ) {
    return navigateTo("/");
  }

  if (config.public.env.VERSION === "PUBLISHED" && to.name === "tenantLogin") {
    return navigateTo("/");
  }
});
